'use client'

import Link from 'next/link'
import { useEffect, useState } from 'react'
import { MdArrowRight, MdWhatsapp } from 'react-icons/md'

import { cn } from '@/lib/utils'

interface Props {
  language?: 'id' | 'en'
}

export const WhatsappSticky = ({ language = 'id' }: Props) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY
      const footerElement = document.querySelector('footer')
      const windowHeight = window.innerHeight
      const documentHeight = document.body.scrollHeight

      if (footerElement) {
        const footerOffset = footerElement.offsetTop
        const scrollPosition = offset + windowHeight

        setShow(scrollPosition < documentHeight - 100 && offset > 1 && scrollPosition < footerOffset)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Link
      tabIndex={0}
      aria-label="Hubungi kami melalu whatsapp"
      href="/hubungi-kami-via-whatsapp"
      className={cn(
        show ? 'right-4 lg:right-9' : '-right-full',
        'fixed flex transition-all duration-700 z-40 items-center gap-2 bottom-4 lg:bottom-7'
      )}
    >
      <div className="text-sm hidden lg:block text-[#14532D] font-medium py-1 relative bg-[#BBF7D0] rounded-full px-2.5">
        <span>{language === 'en' ? 'Contact Us' : 'Hubungi kami'}</span>
        <MdArrowRight size={35} className="absolute -right-4 -top-[3px] text-[#BBF7D0]" />
      </div>

      <div className="rounded-full bg-[#0ACF83]/20 p-4 relative">
        <div className="animate-ping rounded-full absolute bg-[#0ACF83] inline-flex h-[52px] w-[52px]" />
        <div className="p-2 text-white bg-[#16A34A] relative z-10 rounded-full">
          <MdWhatsapp size={37} className="w-9 h-9" />
        </div>
      </div>
    </Link>
  )
}
